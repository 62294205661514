<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Convites
      </div>
    </div>
    <div class="page-title">
      Convites
    </div>
    <div class="invitations-page__actions-container">
      <div class="invitations-page__left-content">
        <StandardInputListFiltered :action="setClientValue" 
                                   :title="'Cliente'" 
                                   :value="filters.client"
                                   class="invitations-page__left-content-button"
                                   :selectWidth="'34.6vw'" 
                                   :propPlaceholder="'Selecione um cliente'" 
                                   :list="arraySelectClient"/>
        <StandardInput :placeholder="'Selecione um status'" 
                       :title="'Status'" 
                       :action="setStatusValue" 
                       :value="filters.status"
                       class="invitations-page__left-content-button"
                       :list="arraySelectStatus" 
                       :type="'select'"/>
        <!-- <div class="invitations-page__actions-wrapper">
          <div class="invitations-page__input-title-wrapper">
            <span class="invitations-page__input-title">Cliente</span>
          </div>
          <cc-select-v2 class="select-input-width"
                        tracker="id"
                        :text="'name'"
                        :searchable="true"
                        :placeholder="'Selecione um cliente'"
                        :options="clients"
                        v-model="filters.client" />
        </div> -->
        <!-- <div class="invitations-page__actions-wrapper">
          <div class="invitations-page__input-title-wrapper">
            <span class="invitations-page__input-title">Status</span>
          </div>
          <cc-select-v2 class="select-input-width"
                        tracker="code"
                        :text="'name'"
                        :placeholder="'Selecione um status'"
                        :options="statuses"
                        v-model="filters.status" />
        </div> -->
      </div>
      <div class="invitations-page__right-content">
        <Button class="action-buttons" :iconName="'campaign'" :disabled="!filters.client" title="Reenviar Notificações" :text="'Reenviar Notificações'" :action="renotify" />
        <Button class="action-buttons" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="clearFilters" />
      </div>
    </div>
    <div class="page-selected-filters-container">
	  <div v-if="filters.client" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.client.name }}</div>
        <span @click="setClientValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.status" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.status.name }}</div>
        <span @click="setStatusValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>
    <cc-loader v-show="is_loading" class="default" />
    <no-data-available v-if="invitations.length == 0 && !is_loading" :text="'Nenhum produto encontrado'" />
    <div v-show="!is_loading" class="invites-page-table-container">
      <div v-show="invitations.length > 0" >
        <div class="page-table-header">
          <div class="page-table-header-text id-width">ID</div>
          <div class="page-table-header-text sended-by-width">Enviador por</div>
          <div class="page-table-header-text recipient-width">Recipiente</div>
          <div class="page-table-header-text phone-width">Telefone</div>
          <div class="page-table-header-text status-width">Status</div>
          <div class="page-table-header-text sended-in-width">Enviado em</div>
          <div class="page-table-header-text company-width">Empresa</div>
        </div>
        <div v-for="(invitation, idx) in invitations" 
             v-bind:key="'head-office-' + invitation.id" 
             :class="{
                       canceled: invitation.status == 'CANCELED',
                       waiting: invitation.status == 'WAITING_CONFIRMATION',
                       confirmed: invitation.status == 'CONFIRMED',
                    }">
          <div :class="getSpecialBackground(idx)" class="page-table-row">
            <div class="page-table-desc-column id-width">
              <div class="page-table-header-mobile">ID</div>
              <div>
                <span style="margin-right: 5px;" class="material-icons-outlined">
                  email
                </span>
                <strong>#{{ invitation.id }}</strong>
              </div>
              
            </div>
            <div class="page-table-desc-column sended-by-width"><div class="page-table-header-mobile">Enviador por</div>{{ invitation.owner.cli_nome_grupo }}</div>
            <div class="page-table-desc-column recipient-width"><div class="page-table-header-mobile">Recipiente</div>{{ invitation.receiver_name }}</div>
            <div class="page-table-desc-column phone-width">
              <div class="page-table-header-mobile">Telefone</div>
              <cc-whatsapp :phone="invitation.receiver_phone" />
            </div>
            <div class="page-table-desc-column status-width">
              <div class="page-table-header-mobile">Status</div>
              <div>
                <cc-select-v2 tracker="code"
                            :text="'name'"
                            @input="(s) => { set_status(invitation, s) }"
                            :options="statuses"
                            v-model="invitation.selected_status">
              </cc-select-v2>
              </div>
              
            </div>
            <div class="page-table-desc-column sended-in-width"><div class="page-table-header-mobile">Enviado em</div>{{ invitation.created_at | formatDate }}</div>
            <div class="page-table-desc-column company-width"><div class="page-table-header-mobile">Empresa</div>{{ invitation.receiver_company_name }}</div>
          </div>
        </div>
      </div>
      <div class="box-footer clearfix not-print">
        <cc-paginacao :key="'pag'"
                      classes="orange"
                      :items_by_page_value="items_by_page_value"
                      :total="total_items"
                      :page="filters.page"
                      :page_limit="11"
                      v-on:change="filters.page = $event">
        </cc-paginacao>
      </div>
    </div>
  </div>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row py-3">
                            <div class="col col-3 filters">
                                <label for="" class="label-control">Cliente</label>
                                <cc-select-v2
                                    tracker="id"
                                    :text="'name'"
                                    :searchable="true"
                                    :placeholder="'Selecione um cliente'"
                                    :options="clients"
                                    v-model="filters.client" />
                            </div>
                            <div class="col col-3 filters">
                                <label for="" class="label-control">Status</label>
                                <cc-select-v2
                                    tracker="code"
                                    :text="'name'"
                                    :placeholder="'Selecione um status'"
                                    :options="statuses"
                                    v-model="filters.status" />
                            </div>
                            <div class="col filter-controls">
                                <cc-button :classes="'fixed danger-outline'"
                                    :icon="'far fa-trash-alt'"
                                    :content="'Limpar Filtros'"
                                    @click="filters={}" />
                                <cc-button :content="'Reenviar Notificações'"
                                    :disabled="!filters.client"
                                    :icon="'fas fa-bullhorn'"
                                    @click="renotify"
                                    :classes="'fixed fill default'"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="is_loading" class="default" />
                        <table class="table" :class="{ hidden : is_loading }">
                            <thead>
                                <tr>
                                    <th class="text-left pl-3">ID</th>
                                    <th>Enviado por</th>
                                    <th>Recipiente</th>
                                    <th>Telefone</th>
                                    <th class="text-left">Status</th>
                                    <th class="text-left">Enviado em</th>
                                    <th>Empresa</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="invitation in invitations" v-bind:key="'head-office-' + invitation.id" :class="{
                                    canceled: invitation.status == 'CANCELED',
                                    waiting: invitation.status == 'WAITING_CONFIRMATION',
                                    confirmed: invitation.status == 'CONFIRMED',
                                }">
                                    <td class="text-left">
                                        <i class="far fa-envelope pr-2"></i>
                                        <strong>#{{ invitation.id }}</strong>
                                    </td>
                                    <td class="text-uppercase">{{ invitation.owner.cli_nome_grupo }}</td>
                                    <td class="text-uppercase">{{ invitation.receiver_name }}</td>
                                    <td>
                                        <cc-whatsapp :phone="invitation.receiver_phone" />
                                    </td>
                                    <td>
                                        <div class="change-status">
                                            <cc-select-v2
                                                tracker="code"
                                                :text="'name'"
                                                @input="(s) => { set_status(invitation, s) }"
                                                :options="statuses"
                                                v-model="invitation.selected_status"></cc-select-v2>
                                            <i class="fa fa-check success-update" :class="{ visible: invitation.saved }" />
                                        </div>
                                    </td>
                                    <td>{{ invitation.created_at | formatDate }}</td>
                                    <td class="text-uppercase">{{ invitation.receiver_company_name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer clearfix not-print">
                    <cc-paginacao :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="filters.page"
                        :page_limit="11"
                        v-on:change="filters.page = $event"></cc-paginacao>
                </div>
            </div>
        </div>
    </div> -->
</template>
<script>

import Paginacao from "@/components/cliente/base-components/Pagination";
import InvitationService from '@/services/v3/invitation.service'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import UserService from "@/services/v1/user.service"
import ClientService from "@/services/v2/client.service";
import Button from '@/components/ui/buttons/StandardButton.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'

export default {
    mixins: [ loaderMixin ],
    components: { 
      ccPaginacao: Paginacao, 
      Button,
      StandardInput,
      StandardInputListFiltered,
    },
    data(){
        return{
            arraySelectClient: [],
            arraySelectStatus: [],
            is_loading: false,
            total_items: 1,
            name_filter: "",
            statuses: [
                {
                    name: 'Aguardando Confirmação',
                    code: 'WAITING_CONFIRMATION'
                },
                {
                    name: 'Confirmado',
                    code: 'CONFIRMED'
                },
                {
                    name: 'Cancelado',
                    code: 'CANCELED'
                }
            ],
            filters: {
                page: 1,
                status: null,
                client: null
            },
            current_client: null,
            provider_filter: null,
            is_client_modal_open: false,
            filterByProviders: [],
            items_by_page_value: 25,
            svc: new InvitationService(),
            user_svc: new UserService(),
            cli_svc: new ClientService(),
            invitations: [],
            clients: [],
            current_provider: null,
            manage_groups: false,
            breadcrumbs: [
                {
                    name: "Meus Vendedores",
                    route: 'sellers'
                }
            ]
        }
    },
    /* watch: {
        filters : {
            handler: function() {
                this.list(1)
            },
            deep: true
        }
    }, */
    methods: {
        setClientValue(value) {
          this.filters.client = value
          this.list(this.page)
        },
        setStatusValue(value) {
          this.filters.status = value
          this.list(this.page)
        },
        clearFilters() {
          this.filters.client = null
          this.filters.status = null
          this.list(this.page)
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        set_status(invitation, s) {
            invitation.selected_status = s
            invitation.status = s.code
            this.svc.update_status({ id: invitation.id, status: s.code })
            .then(response => response.data).then((data)=>{
                invitation.saved = true
                this.$forceUpdate()
            })
        },
        renotify() {
            this.confirm_action({
                subtitle: `#${this.filters.client.name.toUpperCase()}`,
				message: "Confirma reenvio de notificações dos convites pendentes?",
				callback: () => {
                    this.present_loader("Reenviando notificações...")
                    this.svc.notify_all(this.filters.client).then(() => {
                        this.present_info("Notificações Enviadas!")
                    })
				}
			})
        },

        translate_status(status) {
            switch(status) {
                case "CANCELED":
                    return "Cancelado"
                case "WAITING_CONFIRMATION":
                    return "Aguardando Confirmação"
                case "CONFIRMED":
                    return "Confirmado"
                default:
                    return "Não Reconhecido"
            }
        },
        load_region_data() {
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.states = data.estados
			})
		},
        load_client_stores() {
		    	return this.cli_svc.list_client_stores().then(response => response.data).then(data => {
            /* data.data.forEach(element => {
              this.arraySelectClient.push({
                text: element.cli_nome_grupo,
                value: element
              })
            }) */
				    this.clients = data.data.map(cli => { return { id: cli.cli_id, name: cli.cli_nome_grupo } })
            this.clients.forEach(el => {
              this.arraySelectClient.push({
                text: el.name,
                value: el
              })
            })
			    })
		    },
        list() {
            this.is_loading = true;
            let params = {
                page: this.filters.page,
                status: this.filters.status ? this.filters.status.code : null,
                client_id: this.filters.client ? this.filters.client.id : null
            }
            this.svc.list(params)
            .then(response => response.data).then(data => {
                this.invitations = data.data;
                this.total_items = data.total;
                this.is_loading = false;
                this.statuses.forEach(el => {
                  if (this.arraySelectStatus.length < 3) {
                    this.arraySelectStatus.push({
                      text: el.name,
                      value: el
                    })
                  }
                })
                this.invitations.forEach(i => i.selected_status = this.statuses.find((s) => s.code == i.status))
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    created() {
        this.load_region_data()
        this.load_client_stores()
        this.list();
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

    .change-status {
        display: flex;
        align-items: center;
        min-width: 15rem;
        .success-update {
            margin-left: 5px;
            visibility: hidden;
            color: $brand-success;
            &.visibile {
                visibility: 'visible';
            }
        }
    }

    .canceled {
        span {
            color: $brand-danger;
        }
    }
    .waiting {
        span {
            color: $yellow2;
        }
    }
    .confirmed {
        span {
            color: $ternary-color;
        }
    }

    .zap {
        i {
            color:#4AC959;
        }
        width: fit-content;
        &:hover {
            background: #4AC959;
            color: white;
            border-radius: 5px;
            i {
                color: white;
            }
        }
    }

    .actions {
        justify-content: center;
        i {
            margin-left: 10px;
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
        }
    }

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: #ff7110;
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.invitations-page__actions-container {
  display: flex;
  justify-content: space-between;
  margin: 3em 0em;
}
.invitations-page__left-content {
  display: flex;
}
.invitations-page__right-content {
  display: flex;
}
.invitations-page__actions-wrapper {
    margin-right: 20px;
}
.invitations-page__left-content-button{
  width:  35vw; 
  margin-right: 40px;
}
.page-table-header-mobile{
  display: none;
}
.invitations-page__input-title-wrapper {
  padding-bottom: 5px;
}
.invitations-page__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 1.6em;
}
.select-input-width {
  width: 30vw !important;
}
.action-buttons {
  margin-top: 20px;
  margin-left: 10px;
}
.page-selected-filters-container {
  display: flex;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #FF7110;
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: #FF7110;
  cursor: pointer;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.invites-page-table-container {
  margin: 3em 0em;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.id-width {
  width: 8%;
}
.sended-by-width {
  width: 18%;
}
.recipient-width {
  width: 18%;
}
.phone-width {
  width: 12%;
}
.status-width {
  width: 20%;
}
.sended-in-width {
  width: 14%;
}
.company-width {
  width: 8%;
}

@media only screen and (max-width: 950px) {
  .page-container {
    font-size: 1.5vw;
    margin-right: 5vw;
  }
  .page-table-header{
    display: none;
  }
  .page-table-row{
    flex-direction: column;
    font-size: 1.4em;
  }
  .page-table-desc-column {
    display: flex;
    justify-content: space-between;
  }
  .id-width {
    width: 100%;
  }
  .sended-by-width {
    width: 100%;
  }
  .recipient-width {
    width: 100%;
  }
  .phone-width {
    width: 100%;
  }
  .status-width {
    width: 100%;
  }
  .sended-in-width {
    width: 100%;
  }
  .company-width {
    width: 100%;
  }
  .page-table-header-mobile{
    font-weight: 600;
    font-size: 1.1em;
    color: #505050;
    display: flex;
  }
  .invitations-page__actions-container{
    flex-direction: column;
  }
  .invitations-page__left-content{justify-content: space-between;}
  .invitations-page__left-content-button{
    width:  45%; 
    margin-right: 0;
  }
  .action-buttons{
    width: 45%;
    margin-top: 0;
    margin-left: 0;
  }
  .invitations-page__right-content{
    justify-content: space-between;
    margin-top: 3vh;
  }
}
@media only screen and (max-width: 620px){
  .page-container {
    font-size: 2vw;
  }
  
}
@media only screen and (max-width: 480px){
  .page-container {
    font-size: 3vw;
  }
  .invitations-page__left-content{
    flex-direction: column;
    gap: 1vh;
  }
  .invitations-page__left-content-button{
    width:  100%;
  }
  .action-buttons{
    width: 100%;
  }
  .invitations-page__right-content{
    flex-direction: column;
    gap: 3vh;
  }
  
}
</style>
